import { useInView } from '@react-spring/web'
import { useTranslate } from '/machinery/I18n'

import { Tile } from '/features/buildingBlocks/tile/Tile'
import { ShowSkillsMatchPortalFlowButton } from '/features/pageOnly/skillsMatch/buildingBlocks/ShowSkillsMatchPortalFlowButton'

import styles from './AnimatedCardsGrid.css'

export function AnimatedCardsGridOne({ tiles, contextStyle }) {
  return (
    <Base {...{ tiles, contextStyle }} cardClassName={styles.itemOne} className={styles.componentOne} />
  )
}

export function AnimatedCardsGridTwo({ tiles, contextStyle }) {
  return (
    <Base {...{ tiles, contextStyle }} cardClassName={styles.itemTwo} className={styles.componentTwo} />
  )
}

export function AnimatedCardsGridThree({ tiles, contextStyle }) {
  return (
    <Base {...{ tiles, contextStyle }} cardClassName={styles.itemThree} className={styles.componentThree} />
  )
}

export function AnimatedCardsGridFour({ tiles, contextStyle }) {
  return (
    <Base {...{ tiles, contextStyle }} cardClassName={styles.itemFour} className={styles.componentFour} />
  )
}

function Base({ tiles, contextStyle, className, cardClassName }) {
  const [inViewRef, inView] = useInView({ rootMargin: '-50% 0%' })
  const styleContexts = contextStyle === 'blue' ? ['dark', 'blue-dark'] : ['dark', 'blue-dark', 'green']

  return (
    <ul ref={inViewRef} className={cx(styles.componentBase, className)}>
      {tiles?.map((tile, i) => tile.representation?._type && (
        <li key={i} className={cx(cardClassName, inView && styles.compact)}>
          {tile._type === 'tileSkillsmatch'
            ? <SkillsMatchTile availableStyleContexts={styleContexts} blankTile={!inView} layoutClassName={styles.videoTileLayout} {...{ tile }} />
            : <Tile availableStyleContexts={styleContexts} blankTile={!inView} {...{ tile }}  />
          }
        </li>
      ))}
    </ul>
  )
}

function SkillsMatchTile({ layoutClassName, blankTile, tile, availableStyleContexts }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentSkillsMatchTile, layoutClassName)}>
      <Tile {...{ availableStyleContexts, tile, blankTile }}  />
      <ShowSkillsMatchPortalFlowButton
        buttonType='block'
        dataX='link-cta-to-skillsmatchportal'
        ariaLabel={__({ x: __`rabo-skills-title` })`open-x`}
        layoutClassName={styles.skillsMatchButtonLayout}
      />
    </div>
  )
}
